@import '../../static/sass/global/_config.sass';

.download-catalog {
	&__wrapper {
		@include full-wrapper;
		flex-direction: column;
		justify-content: center;
		margin-top: 20px;
		margin-bottom: 120px;
		@include maxwidth($tablet) {
			margin-top: 0;
			margin-bottom: 60px; } }
	&__button {
		width: 420px;
		height: 60px;
		position: relative;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 6px;
		border: solid 2px #7ebf2a;
		background-image: linear-gradient(to bottom, #8fd03c, #7dbe29);
		text-transform: uppercase;
		font: normal 30px/133% $Akrobat;
		color: #2a400e;
		transition: all .5s ease;
		@include maxwidth($mobail) {
			width: calc(100% - 40px); }
		@include maxwidth($md-mobail) {
			font: normal 24px/133% $Akrobat; }
		&:hover {
			opacity: 0.8; } }

	&__link {
		font: normal 24px/108% $Akrobat;
		color: $link;
		margin-top: 30px;
		text-align: center;
		text-decoration: underline !important; } }


