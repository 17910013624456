@import '../../static/sass/global/_config.sass';
.where-to-buy {
    text-align: center;
    padding-bottom: 90px; }

.where-to-buy__map {
    width: 100%;
    height: 500px; }

.where-to-buy__title {
    font: 700 40px/100% Montserrat;
    color: #3e4041;
    margin-bottom: 90px;
    transition: color .5s ease;
    width: 100%;
    display: block;
    text-transform: uppercase; }
