@import '../../static/sass/global/_config.sass';

.about {
	min-height: 400px;
	border-bottom: 1px solid #d9dde3;
	&__wrapper {
		@include full-wrapper;
		flex-direction: column; }

	&__logo {
		display: flex;
		margin: 0 auto 30px;
		width: 300px;
		height: 92px;
		background-size: contain;
		background: url('./img/logo-empils.svg') no-repeat center; }

	&__text {
		font: normal 20px/140% $Akrobat;
		color: $text;
		text-align: left;
		margin: 0 auto 20px;
		max-width: 860px; }

	&__link {
		margin: 0 auto;
		color: $link;
		text-decoration: underline !important;
		font: normal 20px/130% $Akrobat;
		@include maxwidth($mobail) {
			margin-bottom: 50px; } } }
