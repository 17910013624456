@import '../../static/sass/global/_config.sass';

.advantages {
	width: 100%;
	height: 420px;
	min-height: 420px;
	position: relative;
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	overflow: hidden;
	@include maxwidth($sm-desktop) {
		flex-direction: column; }
	@include maxwidth($md-mobail) {
		height: initial; }

	&__item {
		display: flex;
		height: 100%;
		overflow: hidden;
		background-size: cover;
		width: calc(100% / 3 + 50px);
		&:hover {
			.advantages__title {
				color: $primary;
				transform: translateY(0); }
			.advantages__hover {
				opacity: 1; } }

		@include maxwidth($tablet) {
			width: calc(100% / 3 + 40px); }
		@include maxwidth($md-mobail) {
			width: 100%;
			height: 260px; } }

	&__desc {
		margin: auto 0 40px; }

	&__title {
		display: flex;
		font: bold 32px/100% $font;
		color: $darkgray;
		letter-spacing: normal;
		pointer-events: none;
		transition: all .3s ease;
		transform: translateY(70px);
		@include maxwidth($tablet) {
			font: bold 28px/100% $font;
			margin: auto 0 40px 40px !important;
			color: white;
			text-shadow: 0 2px rgba(0, 0, 0, 0.4); }
		@include maxwidth($mobail) {
			font: bold 20px/100% $font; }
		@include maxwidth($md-mobail) {
			color: $darkgray;
			text-shadow: none;
			font: bold 26px/100% $font; } }

	&__hover {
		display: flex;
		flex-direction: column;
		font: normal 20px/150% $font;
		color: #333;
		opacity: 0;
		margin-top: 15px;
		transition: all .3s ease;
		@include maxwidth($mobail) {
			font: normal 16px/140% $font;
			margin-left: 40px !important;
			margin-top: 0; }
		sup {
			bottom: 5px; }
		span {
			margin-left: 0 !important; } }

	.item-ecology {
		background: url('./img/ecology.png') no-repeat;
		z-index: 11;
		@include maxwidth(1800px) {
			background-position: right; }
		@include maxwidth($mobail) {
			width: calc(100% / 3);
			background-position: 80%; }
		@include maxwidth($md-mobail) {
			background-position: 70% 0;
			position: relative;
			width: 100%; }

		span {
			margin-left: 50px;
			@include maxwidth($tablet) {
				margin-left: initial; } } }

	.item-economy {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		background: url('./img/economy.png') no-repeat;
		background-position-x: right;
		z-index: 10;
		@include maxwidth(1800px) {
			background-position: right; }
		@include maxwidth($mobail) {
			width: calc(100% / 3);
			background-position: 80%; }
		@include maxwidth($md-mobail) {
			background-position: 70% 0;
			position: relative;
			width: 100%; }

		span {
			margin-left: 60px;
			@include maxwidth($tablet) {
				margin-left: initial; } } }

	.item-effective {
		background: url('./img/effective.png') no-repeat;
		position: absolute;
		right: 0;
		z-index: 9;
		@include maxwidth(1800px) {
			background-position: right; }
		@include maxwidth($mobail) {
			width: calc(100% / 3);
			background-position: 70%; }
		@include maxwidth($md-mobail) {
			background-position: 70% 5%;
			position: relative;
			width: 100%; }

		span {
			margin-left: 80px;
			@include maxwidth($tablet) {
				margin-left: initial; } } } }
