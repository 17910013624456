@charset "UTF-8";

$black: #000000;
$white: #ffffff;

$primary: #bc1f2f;
$darkgray: #333333;
$lightgray: #6b7b86;
$text: #3e4041;
$link: #4a90e2;

$font: 'Montserrat';
$Akrobat: 'Akrobat';

// Основные разрешения
// ===================
$md-desktop: 1700px;
$desktop: 1300px;
$sm-desktop: 1100px;
$tablet: 991px;
$mobail: 767px;
$md-mobail: 630px;
$sm-mobail: 575px;
$min-width: 325px;


@mixin maxwidth($w) {
	@media only screen and (max-width: $w) {
		@content; } }

@mixin maxheight($h) {
	@media only screen and (max-height: $h) {
		@content; } }

@keyframes anim {
	0% {
		opacity: 0;
		-webkit-transform: translateY(25px);
		-moz-transform: translateY(25px);
		-ms-transform: translateY(25px);
		-o-transform: translateY(25px);
		transform: translateY(25px); }

	100% {
		opacity: 1;
		-webkit-transform: translateY(0px);
		-moz-transform: translateY(0px);
		-ms-transform: translateY(0px);
		-o-transform: translateY(0px);
		transform: translateY(0px); } }

@mixin full-wrapper {
	margin: 0 auto;
	position: relative;
	display: flex;
	max-width: 1300px;
	width: 100%;
	@media only screen and (max-width: $desktop) {
		max-width: calc(100% - 30px); }
	@media only screen and (max-width: $mobail) {
		max-width: calc(100% - 20px); } }
