@import '../../static/sass/global/_config.sass';

.feature {
	&__char {
		display: flex;
		align-items: center;
		min-height: 64px;
		margin-bottom: 20px;

		.char-ico {
			width: 60px;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				//max-width: 100%
				height: auto; } }

		.char-desc {
			display: flex;
			flex-direction: column;
			padding-left: 20px;
			max-width: 530px; }

		.char-text {
			font: normal 20px/120% $Akrobat;
			color: $text;
			white-space: pre-line;
			@include maxwidth($mobail) {
				white-space: normal;
				font: normal 16px/110% $Akrobat; } }

		.char-subtext {
			font: normal 14px/143% $Akrobat;
			color: $text;
			white-space: pre-line; } } }
