@import '../../static/sass/global/_config.sass';

.protector {
	&__wrapper {
		@include full-wrapper;
		width: 100%;
		max-height: 600px; }

	&__banner {
		width: 100%;
		height: 600px;
		background: url('./img/protector.png') no-repeat center / contain;
		cursor: pointer;
		transition: all .4s ease;
		&:hover {
			opacity: 0.8; }
		@include maxwidth($mobail) {
			max-height: 300px;
			max-width: calc(100% - 30px);
			margin: 0 auto; } } }

