@import '../../static/sass/global/_config.sass';

.catalog {
	width: 100%;
	min-height: 100vh;
	background: $white;
	&__wrapper {
		@include full-wrapper;
		padding-top: 90px;
		display: flex;
		flex-direction: column;
		@include maxwidth($sm-desktop) {
			padding-top: 30px; }
		@include maxwidth($mobail) {
			max-width: calc(100% - 60px); } } }
