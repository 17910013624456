@import '../../static/sass/global/_config.sass';

.footer {
	width: 100%;
	height: 80px;
	border-top: 1px solid #d9dde3;
	&__wrapper {
		@include full-wrapper;
		height: 100%;
		align-items: center;
		justify-content: center; }
	&__logo {
		display: flex;
		background: url('./img/logo-minimal.svg') no-repeat center / cover;
		width: 61px;
		height: 40px; } }
