@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat-Thin"), local("Montserrat-Thin"), url("./static/fonts/Montserrat-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat-ExtraLight"), local("Montserrat-ExtraLight"), url("./static/fonts/Montserrat-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat-Light"), local("Montserrat-Light"), url("./static/fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat-Regular"), local("Montserrat-Regular"), url("./static/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat-Medium"), local("Montserrat-Medium"), url("./static/fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat-SemiBold"), local("Montserrat-SemiBold"), url("./static/fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat-Bold"), local("Montserrat-Bold"), url("./static/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat-ExtraBold"), local("Montserrat-ExtraBold"), url("./static/fonts/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat-Black"), local("Montserrat-Black"), url("./static/fonts/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Akrobat';
  src: local("Akrobat"), local("Akrobat"), url("./static/fonts/Akrobat.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Akrobat';
  src: local("Akrobat"), local("Akrobat"), url("./static/fonts/Akrobatbold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@keyframes anim {
  0% {
    opacity: 0;
    -webkit-transform: translateY(25px);
    -moz-transform: translateY(25px);
    -ms-transform: translateY(25px);
    -o-transform: translateY(25px);
    transform: translateY(25px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); } }

* {
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

*, *::before, *::after {
  box-sizing: border-box; }

html {
  background: white;
  color: black;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%; }

body {
  overflow-x: hidden;
  min-height: 100%;
  position: relative;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100vw; }

a, input, button, textarea, *:before, *:after, *:hover, *:active, *:focus {
  outline: none; }

a {
  text-decoration: none !important;
  transition: color .3s ease, opacity .3s ease, border .3s ease, background .3s ease;
  display: inline-block; }
  a:hover, a:active, a:focus {
    outline: none;
    text-decoration: none !important; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

abbr[title] {
  border-bottom: none; }

audio, canvas, video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], .hidden {
  display: none !important; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

mark {
  background: #ff0;
  color: #000; }

code, kbd, pre, samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap; }

q {
  quotes: '\201C' '\201D' '\2018' '\2019'; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button, input {
  line-height: normal; }

button, select {
  text-transform: none; }

button, html input[type='button'], input[type='reset'], input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

input[type='checkbox'], input[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

input[type='search'] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input::-webkit-search-cancel-button, input::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }
