@import '../../static/sass/global/_config.sass';

.menu {
	width: 100%;
	min-height: 275px;
	display: flex;

	&__wrapper {
		width: 100%;
		max-width: 1300px;
		margin: 0 auto;
		display: flex;
		flex-direction: column; }

	&__title {
		margin: 80px 0 30px;
		font: normal 32px/110% $font;
		color: $darkgray;
		text-align: center;
		display: flex;
		flex-direction: column;
		text-transform: uppercase;
		@include maxwidth($sm-desktop) {
			margin: 40px 0; }
		@include maxwidth($mobail) {
			font: normal 22px/120% $font; } }

	&__subtitle {
		font: bold 80px/100% $font;
		color: $primary;
		text-transform: uppercase;
		@include maxwidth($mobail) {
			font: bold 40px/100% $font; } }

	&__nav {
		width: 100%;
		padding: 0 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include maxwidth($sm-desktop) {
			padding: 0 15px; }
		@include maxwidth($mobail) {
			flex-wrap: wrap; }

		&.sticky {
			background: $white;
			position: fixed;
			top: 0;
			box-shadow: 0 2px 4px 0 rgba(51, 76, 104, 0.2);
			width: 100%;
			height: 60px;
			padding: 0 calc((100% - 1300px) / 2);
			z-index: 200;
			left: 0;
			@include maxwidth($mobail) {
				display: none; } } }


	&__item {
		margin-right: 60px;
		font: 600 12px/130% $font;
		color: $black;
		text-transform: uppercase;
		text-align: center;
		transition: color .4s ease;
		cursor: pointer;
		@include maxwidth($sm-desktop) {
			font: 600 10px/130% $font;
			margin-right: 0; }
		@include maxwidth($mobail) {
			display: flex;
			width: calc(100% / 4);
			justify-content: center;
			margin-bottom: 20px; }
		@include maxwidth($md-mobail) {
			width: 50%; }
		&:hover {
			color: $primary; }
		&:last-child {
			margin-right: 0; }
		&.active {
			color: $primary; }
		strong {
			font: 600 10px/100% $font;
			color: $primary; } } }
