@import '../../static/sass/global/_config.sass';

.catalog-item {
	display: flex;
	margin-bottom: 90px;
	@include maxwidth($sm-desktop) {
		flex-direction: column; }
	@include maxwidth($mobail) {
		margin-bottom: 60px; }

	&__info {
		margin-right: 60px;
		width: calc(100% - 770px);
		display: flex;
		flex-direction: column;
		@include maxwidth($sm-desktop) {
			width: 100%;
			margin-top: 70px; }

		&.right-info {
			margin-left: auto;
			margin-right: 0; } }

	&__title {
		font: bold 36px/100% $Akrobat;
		color: $text;
		margin-bottom: 12px;
		transition: color .5s ease;
		max-width: 500px;
		@include maxwidth($sm-desktop) {
			text-align: center; }
		&.active {
			color: $primary; } }

	&__desc {
		font: normal 20px/130% $Akrobat;
		color: $text;
		margin-bottom: 20px;
		@include maxwidth($sm-desktop) {
			padding: 0 30px; }
		@include maxwidth($sm-mobail) {
			padding: 0;
			margin-top: 10px; } }

	&__chars {
		display: flex;
		flex-direction: column;
		padding-left: 16px;
		@include maxwidth($sm-desktop) {
			padding-left: 30px; }
		@include maxwidth($md-mobail) {
			padding-left: 0; } }

	&__image {
		position: relative;
		display: flex;
		max-width: 530px;
		margin-left: 0px;
		@include maxwidth($sm-desktop) {
			margin: 0 auto;
			order: -1;
			max-width: 800px; }

		&.right-image {
			order: -1;
			margin-left: -110px;
			@include maxwidth($sm-desktop) {
				margin: 0 auto; }

			.catalog-item__overlay {
				background: url('./img/pics/maskleft.png') no-repeat center / cover;
				span {
					@include maxwidth($sm-desktop) {
						margin-left: 200px;
						margin-right: 0px; } } }

			.catalog-item__tank {
				left: initial;
				right: -350px;
				@include maxwidth($sm-desktop) {
					top: -150px;
					left: -300px;
					right: 0;
					margin: 40% auto;
					bottom: 0; }
				@include maxwidth($mobail) {
					display: flex;
					max-width: 80%;
					margin: 0 auto;
					top: 0px;
					left: 20px; } } }

		&.big-tank {
			.catalog-item__tank {
				right: 0;
				left: -110%;
				bottom: 0;
				margin: auto;
				@include maxwidth($sm-desktop) {
					top: initial;
					bottom: -100px;
					left: -10%; }
				@include maxwidth($mobail) {
					max-width: 100%;
					left: 0; } } }

		&.right-image.big-tank {
			.catalog-item__tank {
				left: initial;
				top: 150px;
				right: -60%;
				@include maxwidth($sm-desktop) {
					top: initial;
					left: -10%;
					right: 0;
					bottom: -100px; }
				@include maxwidth($mobail) {
					max-width: 100%;
					left: 0; } } } }

	&__bg {
		width: 800px;
		height: 530px;
		margin-top: 5px;
		background-repeat: no-repeat;
		position: relative;
		overflow: hidden;
		@include maxwidth($mobail) {
			display: none; } }

	&__overlay {
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;
		position: absolut;
		width: 100%;
		height: 100%;
		transition: all .4s ease;
		background: url('./img/pics/mask.png') no-repeat center / cover;
		span {
			color: #fff;
			font: normal 35px/120% $Akrobat;
			text-align: center;
			@include maxwidth($sm-desktop) {
				margin-right: 200px; } }
		&.show {
			opacity: 0.85;
			/*background: #32aaeb*/ } }

	&__link {
		&:hover {
			.catalog-item__tank {
				transform: scale(1.1); } } }
	&__tank {
		position: absolute;
		top: 100px;
		bottom: 50px;
		left: 0px;
		transition: all .5s ease;

		@include maxwidth($sm-desktop) {
			top: -150px;
			left: 470px;
			right: 0;
			margin: 40% auto;
			bottom: 0; }
		@include maxwidth($mobail) {
			display: flex;
			max-width: 80%;
			top: 0px;
			left: 20px;
			position: relative;
			margin: 0 auto; } } }


