@import '../../static/sass/global/_config.sass';

.form {
	padding-bottom: 40px;
	display: flex;
	@include maxwidth($md-mobail) {
		min-height: 800px; }

	&__wrapper {
		@include full-wrapper;
		flex-direction: column;
		align-items: center; }

	&__container {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		transition: all .2s ease; }

	&__title {
		font: bold 40px/100% $font;
		color: $text;
		text-align: center;
		margin: 60px 0 10px;
		text-transform: uppercase;
		@include maxwidth($sm-mobail) {
			font: bold 30px/100% $font; } }

	&__subtitle {
		font: normal 20px/130% $Akrobat;
		color: $text;
		margin-bottom: 37px;
		@include maxwidth($md-mobail) {
			font: normal 16px/130% $Akrobat;
			text-align: center; } }

	&__group {
		width: 100%; }

	&__row {
		display: flex;
		justify-content: space-between;
		margin: 0 auto 30px;
		width: calc(100% - 310px);
		@include maxwidth(1300px) {
			width: calc(100% - 150px); }
		@include maxwidth(1100px) {
			width: calc(100% - 100px); }
		@include maxwidth($md-mobail) {
			width: calc(100% - 30px);
			flex-direction: column;
			margin: 0 auto; }
		&.question-row {
			margin: 0 auto 20px; } }

	&__input {
		position: relative;
		width: calc(100% / 3 - 30px);
		@include maxwidth($mobail) {
			width: calc(100% / 3 -  15px); }
		@include maxwidth($md-mobail) {
			width: 100%;
			margin-bottom: 30px; }

		input {
			border-radius: 3px;
			width: 310px;
			height: 40px;
			border: 1px solid #d9dde3;
			background: $white;
			padding-left: 10px;
			display: flex;
			font: normal 18px/144% $Akrobat;
			@include maxwidth($sm-desktop) {
				width: 100%; }
			@include maxwidth($md-mobail) {
				height: 60px;
				padding-left: 15px; }

			&:focus ~ label, &:valid ~ label {
				font-size: 14px;
				top: -10px;
				padding: 0 3px;
				background: #fff; } }

		label {
			pointer-events: none;
			font: normal 18px/144% $Akrobat;
			color: $text;
			display: flex;
			position: absolute;
			left: 10px;
			top: 7px;
			transition: all .3s ease;
			@include maxwidth($md-mobail) {
				font: normal 20px/144% $Akrobat;
				top: 15px;
				left: 15px; }
			span {
				@include maxwidth($tablet) {
					display: none; } } } }



	&__agreement {
		margin: 0 auto;
		text-align: center;
		font: normal 14px/143% $Akrobat;
		color: $text;
		span {
			color: $link;
			text-decoration: underline; } }

	&__button {
		margin: 20px auto 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 310px;
		height: 50px;
		border: solid 2px #7ebf2a;
		border-radius: 3px;
		background-image: linear-gradient(to bottom, #8fd03c, #7dbe29);
		color: #2a400e;
		font: normal 20px/130% $Akrobat;
		text-align: center;
		text-transform: uppercase;
		transition: all .5s ease;
		&:hover {
			opacity: 0.8; }
		&.err {
			border: 1px solid red; } }

	.recaptcha-container {
		display: flex;
		justify-content: center;
		margin-bottom: 20px; }

	&__success {
		display: none;
		margin: auto;
		text-align: center;
		transition: all .3s ease;
		.success-title {
			font: bold 40px/100% $font;
			color: #000;
			margin-bottom: 20px; }
		span {
			font: normal 20px/100% $font;
			color: #000; }
		&.shown {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center; } }

	.question {
		width: 100%;
		input {
			width: 100%; } } }

