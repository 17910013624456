@import '../../static/sass/global/_config.sass';

.social {
	border-top: 1px solid #d9dde3;
	padding: 20px; }

.social__list {
	margin: 0 -10px 0 0;
	padding: 0;
	list-style: none;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
	@include maxwidth($mobail) {
		flex-direction: column; } }

.social__item {
	height: 40px; }

.social__link {
	display: block;
	width: 100%;
	height: 35px;
	background-repeat: no-repeat;
	background-position: center center;
	transition: 0.3s ease-in-out;
	font: normal 20px/130% $font;
	color: #4a90e2;

	&:hover {
		opacity: 0.8; }

	&--youtube {
		background-image: url("img/icon-youtube.svg");
		width: 177px;
		background-size: contain;
		height: 100%; }

	&--rutube {
		background-image: url("img/icon-rutube.svg");
		width: 157px;
		height: 100%; } }
