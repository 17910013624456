// Шрифты
//===========

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat-Thin'), local('Montserrat-Thin'), url('./static/fonts/Montserrat-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
	font-display: swap; }

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat-ExtraLight'), local('Montserrat-ExtraLight'), url('./static/fonts/Montserrat-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
	font-display: swap; }

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat-Light'), local('Montserrat-Light'), url('./static/fonts/Montserrat-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap; }

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat-Regular'), local('Montserrat-Regular'), url('./static/fonts/Montserrat-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap; }

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat-Medium'), local('Montserrat-Medium'), url('./static/fonts/Montserrat-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap; }

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat-SemiBold'), local('Montserrat-SemiBold'), url('./static/fonts/Montserrat-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap; }

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat-Bold'), local('Montserrat-Bold'), url('./static/fonts/Montserrat-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap; }

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat-ExtraBold'), local('Montserrat-ExtraBold'), url('./static/fonts/Montserrat-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
	font-display: swap; }

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat-Black'), local('Montserrat-Black'), url('./static/fonts/Montserrat-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: swap; }

@font-face {
	font-family: 'Akrobat';
	src: local('Akrobat'), local('Akrobat'), url('./static/fonts/Akrobat.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap; }

@font-face {
	font-family: 'Akrobat';
	src: local('Akrobat'), local('Akrobat'), url('./static/fonts/Akrobatbold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap; }

// Общие стили
//===========

//@import-normalize
@import "./static/sass/global/config";
@import "./static/sass/global/reset";
