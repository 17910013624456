@import '../../static/sass/global/_config.sass';

.ReactModal__Body--open {
	overflow-y: hidden; }

.ReactModal__Overlay {
	transform: translateY(-150px);
	opacity: 0;
	transition: all .5s ease;
	z-index: 1000;
	inset: 0; }

.ReactModal__Content {
	width: 100%;
	height: 100%;
	inset: 0 !important;
	border: none !important;
	border-radius: 0 !important;
	padding: 0;
	margin: 0;
	@include maxwidth($mobail) {
		padding: 0 !important; }

	&--after-open {
		inset: 0 !important;
		top: 0 !important;
		left: 0 !important;
		right: 0 !important;
		bottom: 0 !important; } }

.ReactModal__Overlay--after-open {
	opacity: 1;
	transform: translateY(0); }


.ReactModal__Overlay--before-close {
	opacity: 0; }


.modal {
	display: flex;
	flex-direction: column;
	@include maxwidth($mobail) {
		padding: 0; }

	&__close {
		position: absolute;
		border: none;
		right: 20px;
		background: url(./img/icons/close.svg) no-repeat center / cover;
		width: 46px;
		height: 46px; }

	&__header {
		margin: 40px auto 0;
		text-align: center;
		@include maxwidth($mobail) {
			margin: 60px auto 0; }

		&-future {
			font: normal 32px/100% $font;
			color: $darkgray;
			text-transform: uppercase;
			@include maxwidth($mobail) {
				font: normal 28px/100% $font;
				margin-bottom: 5px; } }

		&-title {
			font: bold 60px/100% $font;
			color: $primary;
			margin-bottom: 10px;
			@include maxwidth($mobail) {
				font: bold 40px/100% $font; } }

		&-subtitle {
			font: bold 20px/100% $font;
			color: $darkgray;
			text-transform: uppercase;
			margin-bottom: 40px;
			@include maxwidth($mobail) {
				font: bold 18px/100% $font; } } }


	&__body {
		display: flex;
		justify-content: space-between;
		align-self: center;
		max-width: 100%;
		width: 70%;
		@include maxwidth($sm-desktop) {
			width: 100%; }
		@include maxwidth($mobail) {
			flex-direction: column;
			align-items: center; } }


	&__item {
		max-width: 530px;
		@include maxwidth($sm-desktop) {
			max-width: 48%; }
		@include maxwidth($mobail) {
			max-width: 80%;
			margin: 0 auto 60px; }


		&--interior {
			margin-right: 130px;
			@include maxwidth($sm-desktop) {
				margin-right: 0; }

			.modal__item-bg {
				background: url('./img/int-protector-img.png') no-repeat center / contain; }

			.modal__item-tank {
				background: url('./img/int-protector-tank.png') no-repeat center / contain; } }


		&--facade {
			.modal__item-bg {
				background: url('./img/fa-protector-img.png') no-repeat center / contain; }

			.modal__item-tank {
				background: url('./img/fa-protector-tank.png') no-repeat center / contain; } }

		&-image {
			max-width: 100%;
			display: flex;
			align-items: center;
			margin-bottom: 40px;
			@include maxwidth($mobail) {
				margin-bottom: 0; } }

		&-tank {
			width: 280px;
			height: 185px;
			margin-left: -150px;
			margin-top: 100px; }

		&-bg {
			width: 318px;
			height: 318px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain; }

		&-title {
			font: bold 36px/100% $Akrobat;
			color: $text;
			margin-bottom: 10px;
			max-width: 530px;
			@include maxwidth($mobail) {
				font: bold 30px/100% $Akrobat; } }

		&-desc {
			font: normal 20px/130% $Akrobat;
			color: $text;
			margin-bottom: 30px; }

		&-features {
			padding-left: 20px; }

		&-feature {
			display: flex;
			align-items: center;
			margin-bottom: 22px;

			img {
				max-width: 60px;
				max-height: 60px;
				margin-right: 20px; }

			span {
				font: normal 20px/120% $Akrobat;
				color: $text; } } } }
