* {
	outline: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0; }

*, *::before, *::after {
	box-sizing: border-box; }

html {
	background: white;
	color: black;
	//font-family: sans-serif
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	height: 100%; }

body {
	overflow-x: hidden;
	min-height: 100%;
	position: relative;
	height: 100%;
	margin: 0;
	padding: 0;
	width: 100vw; }

a, input, button, textarea, *:before, *:after, *:hover, *:active, *:focus {
	outline: none; }

a {
	text-decoration: none !important;
	transition: color .3s ease, opacity .3s ease, border .3s ease, background .3s ease;
	display: inline-block;

	&:hover, &:active, &:focus {
		outline: none;
		text-decoration: none !important; } }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
	display: block; }

abbr[title] {
	border-bottom: none; }

audio, canvas, video {
	display: inline-block; }

audio:not([controls]) {
	display: none;
	height: 0; }

[hidden], .hidden {
	display: none !important; }

b, strong {
	font-weight: bold; }

dfn {
	font-style: italic; }

hr {
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	height: 0; }

mark {
	background: #ff0;
	color: #000; }

code, kbd, pre, samp {
	font-family: monospace, serif;
	font-size: 1em; }

pre {
	white-space: pre-wrap; }

q {
	quotes: '\201C' '\201D' '\2018' '\2019'; }

small {
	font-size: 80%; }

sub, sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline; }

sup {
	top: -0.5em; }

sub {
	bottom: -0.25em; }

img {
	border: 0; }

svg:not(:root) {
	overflow: hidden; }

figure {
	margin: 0; }

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em; }

legend {
	border: 0;
	padding: 0; }

button, input, select, textarea {
	font-family: inherit;
	font-size: 100%;
	margin: 0; }

button, input {
	line-height: normal; }

button, select {
	text-transform: none; }

button, html input[type='button'], input[type='reset'], input[type='submit'] {
	-webkit-appearance: button;
	cursor: pointer; }

button[disabled], html input[disabled] {
	cursor: default; }

input {
	&[type='checkbox'], &[type='radio'] {
		box-sizing: border-box;
		padding: 0; }

	&[type='search'] {
		-webkit-appearance: textfield;
		-moz-box-sizing: content-box;
		-webkit-box-sizing: content-box;
		box-sizing: content-box; }

	&::-webkit-search-cancel-button, &::-webkit-search-decoration {
		-webkit-appearance: none; } }

button::-moz-focus-inner, input::-moz-focus-inner {
	border: 0;
	padding: 0; }

textarea {
	overflow: auto;
	vertical-align: top; }

table {
	border-collapse: collapse;
	border-spacing: 0; }


article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
	display: block; }
