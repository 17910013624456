@import '../../static/sass/global/config';

.initial-screen {
	width: 100%;
	max-height: 100vh;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	position: relative;
	background: url('./img/background.png');
	background-size: cover;
	overflow: hidden;
	transition: all 3s ease;
	//+maxheight(900px)
	//	max-height: initial
	//	.initial-screen__logo
	//		width: 730px
	@include maxwidth($md-mobail) {
		min-height: 600px; }

	&__badge {
		position: relative;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: #013727;
		width: 326px;
		min-height: 118px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		padding: 10px 30px;
		pointer-events: none;
		@include maxwidth($mobail) {
			max-width: 100%;
			min-height: 60px; }
		@include maxwidth($sm-mobail) {
			width: 100%; }


		span {
			display: block;
			font: 1000 40px/110% 'Arial', sans-serif;
			color: $white;
			text-align: center;
			@include maxwidth($mobail) {
				font: 1000 30px/110% 'Arial', sans-serif; } } }

	&__logo {
		background: url('./img/logo.png') no-repeat center / cover;
		width: 660px;
		height: 650px;
		display: flex;
		margin: 70px auto 0;
		opacity: 0;
		pointer-events: none;
		animation: moveUpLogo 2s ease .5s;
		@include maxheight(900px) {
			width: 730px;
			background-size: contain !important; }
		@include maxwidth($sm-desktop) {
			width: 730px;
			height: 480px;
			background-size: cover !important; }
		@include maxwidth($mobail) {
			width: 100% !important;
			height: 505px; }
		@include maxwidth($md-mobail) {
			height: 405px;
			margin-top: 40px; }

		&.active {
			opacity: 1; } }


	&__red-logo {
		visibility: hidden;
		pointer-events: none;
		max-width: 845px;
		width: 845px;
		height: 73px;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		font: bold 102px/100% $font;
		color: $primary;
		text-transform: uppercase;
		z-index: 30;
		top: -50px;
		left: 0;
		right: 0;
		bottom: 0;
		animation: moveUpRedLogo 1s ease 2.5s;

		&.active {
			visibility: visible; }

		@include maxwidth(1830px) {
			font-size: 5em; }
		@include maxwidth($sm-desktop) {
			max-width: 100%; }
		@include maxwidth($mobail) {
			font: bold 12vw/100% $font; } }


	&__town {
		margin: auto;
		position: absolute;
		top: -40px;
		left: 100px;
		right: 0;
		bottom: 0;
		z-index: 10;
		width: 1050px;
		height: 900px;
		background: url('./img/center-town.png') no-repeat center / cover;
		@include maxwidth($sm-desktop) {
			left: 0;
			max-width: 100%;
			width: 100%; }
		@include maxwidth($sm-mobail) {
			height: auto; } }


	&__cloud-wrap {
		width: 100%;
		height: 450px;
		margin: 0 auto;
		position: relative;
		max-width: 100%;
		overflow: hidden;
		z-index: 20;
		transform: translate3d(0, 0, 0);
		@include maxwidth($sm-desktop) {
			display: none; } }

	&__clouds {
		background: url('./img/clouds.png') no-repeat 50% 15%;
		width: 6873px;
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		z-index: 20;
		//transform: translate3d(0, 0, 0)
		animation: moving 100s linear infinite; }

	&__l-tree {
		background: url(./img/l.png) no-repeat center / cover;
		position: absolute;
		left: -380px;
		top: 0;
		bottom: 0;
		width: 955px;
		height: 940px;
		z-index: 30;
		transition: all .5s ease;
		@include maxwidth(1830px) {
			left: calc(100% - 130vw); }
		@include maxwidth(1500px) {
			left: calc(100% - 145vw); }
		@include maxwidth(1280px) {
			left: calc(100% - 157vw); }
		@include maxwidth($sm-desktop) {
			display: none; } }

	&__r-tree {
		background: url(./img/r.png) no-repeat center / cover;
		position: absolute;
		right: -320px;
		top: 0;
		width: 800px;
		height: 960px;
		z-index: 30;
		transition: all .5s ease;
		@include maxwidth(1830px) {
			right: calc(100% - 130vw); }
		@include maxwidth(1500px) {
			right: calc(100% - 137vw); }
		@include maxwidth(1280px) {
			right: calc(100% - 155vw); }
		@include maxwidth($sm-desktop) {
			display: none; } }

	&__l-bush {
		background: url('./img/l-bott.png') no-repeat right / cover;
		width: 989px;
		height: 365px;
		position: absolute;
		left: -500px;
		bottom: 0;
		z-index: 40;
		@include maxwidth($sm-desktop) {
			display: none; } }

	&__r-bush {
		background: url('./img/r-bott.png') no-repeat center / cover;
		width: 1093px;
		height: 282px;
		position: absolute;
		right: -520px;
		bottom: 0;
		z-index: 40;
		@include maxwidth($sm-desktop) {
			display: none; } }

	&__grass {
		background: url('./img/grass.png') no-repeat center / cover;
		position: absolute;
		bottom: 0;
		z-index: 10;
		max-width: 100%;
		width: 100%;
		height: 250px;
		@include maxheight(900px) {
			height: 200px; }
		@include maxwidth($sm-desktop) {
			height: 210px; }
		@include maxwidth($mobail) {
			height: 150px; } }

	&__mouse-wrap {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		@include maxwidth($sm-mobail) {
			bottom: 30px; } }

	.arrow-link {
		z-index: 100; }

	.scroll-arrow {
		cursor: pointer;
		animation: arrow 3s ease infinite;
		z-index: 1000;
		fill: #FFF;
		transition: fill .3s ease;
		&:hover {
			fill: #000; } }

	&__mouse-ico {
		background: url('./img/mouse.svg') no-repeat center / cover;
		width: 28px;
		height: 41px;
		z-index: 200;
		display: flex; }


	&__mouse {
		width: 80px;
		height: 80px;
		position: absolute;
		right: 0;
		left: 0;
		margin: 0 auto;
		background: $white;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		opacity: .8;
		z-index: 40;
		animation: fading 5s ease;

		&:before {
			content: '';
			background: $white;
			width: 112px;
			height: 112px;
			opacity: .5;
			border-radius: 50%;
			position: absolute; }

		&:after {
			content: '';
			background: $white;
			width: 146px;
			height: 146px;
			opacity: .3;
			position: absolute;
			border-radius: 50%;
			animation: pulseBefore 3s ease infinite; } }

	&__ico {
		opacity: 1;
		display: flex;
		background: $white;
		width: 30px;
		height: 30px;
		position: relative;
		border-radius: 50%;
		z-index: 150;
		animation: pulseAfter 3s ease infinite; } }

@keyframes fading {
	0% {
		opacity: 0; }
	100% {
		opacity: .8; } }

@keyframes moveUpRedLogo {
	0% {
		opacity: 0;
		transform: translateY(150px); }
	100% {
		opacity: 1;
		transform: translateY(0); } }

@keyframes moveUpLogo {
	0% {
		opacity: 0.1;
		transform: translateY(450px); }
	100% {
		opacity: 1;
		transform: translateY(0); } }

@keyframes moving {
	100% {
		transform: translateX(-60%); } }

@keyframes arrow {
	0% {
		transform: translateY(0); }

	50% {
		transform: translateY(15px); }

	100% {
		transform: translateY(0); } }
